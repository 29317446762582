import React from 'react'
import Layout from "../components/layout";
// import Breadcrumbs from "../components/breadcrumbs";
// import PageHeading from "../components/pageHeading";
// import GetStarted from '../..components/deals/getStarted';
import cx from 'classnames';
import Styles from '../assets/styles/dealComparison.module.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faChevronLeft)




const DealComparison = () => (
    <Layout>
        {/* <Breadcrumbs />
        <PageHeading /> */}

        <section className={Styles.dealcomparisonSection}>
            <div className={cx('container', Styles.customContainer)}>
                <div className={'row'}>
                    <div className={cx(Styles.dealcomparisonContnt, 'col-lg-12')}>
                    
                        <div className={Styles.gobackbtn}>
                      <a className={Styles.gobacklink} href="javascript://">
                          <i className={cx(Styles.fa, Styles.faChevronLeft)} aria-hidden="true">
                          <FontAwesomeIcon icon={faChevronLeft} />
                          </i>
                          <span className={Styles.btntxt}>Back to results</span>
                      </a>
                    </div>
                    <h1> Home loan comparison</h1>
                        <div className={cx('table-responsive', Styles.dealComparisonTableMain)}>
                            <table className={cx('table', Styles.table, Styles.tableBordered, Styles.dealComparisonTable)}>
                                <thead>
                                    <tr className={Styles.dealTypeHeadr}>
                                        <th className={Styles.dealTypeTitle}>
                                            <span>
                                            </span>
                                        </th>
                                        <th className={Styles.dealTypeTitle}>
                                            <span>Deal 1</span>
                                        </th>
                                        <th className={Styles.dealTypeTitle}>
                                            <span>Deal 2</span>
                                        </th>
                                        <th className={Styles.dealTypeTitle}>
                                            <span>Deal 3</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={cx(Styles.dealTypeContnt, Styles.firstrowContnt)}>
                                        <th className={Styles.dealContntHeading}>Variable rate (p.a.)</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>3.49%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>3.49%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>3.49%</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Comparison rate* (p.a.)</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>3.55%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>4.33%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>3.55%</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Loan to Value Ratio</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>80%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>80%</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>80%</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Application Fee</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>0.35% of loan amount</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$0</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$275</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Ongoing Fee</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>None</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$234 Annually</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$234 Annually</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Repayment Type</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>P &amp; I</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>Interest only</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>P &amp; I</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className={Styles.dealContntHeading}>Monthly Repayments</th>
                                        <td className={Styles.dealContntValue}>
                                            <span>$1900</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$1900</span>

                                        </td>
                                        <td className={Styles.dealContntValue}>
                                            <span>$1900</span>

                                        </td>
                                    </tr>
                                    <tr className={cx(Styles.dealFeatures)}>
                                        <th className={Styles.dealContntHeading}>Other Features</th>
                                        <td>
                                           
                                                <ul className={Styles.dealfeatureList}>
                                                    <li><span>Big 4 bank with interest rate discount applicable for first 2 years</span></li>
                                                    <li><span>Owner Occupied with Principal and Interest repayments only</span></li>
                                                    <li><span>No Home Loan Establishment Fee</span></li>
                                                    <li><span>Make additional loan repayments allowed. Redraw facility available</span></li>
                                                </ul>

                                            

                                        </td>
                                        <td>
                                        <ul className={Styles.dealfeatureList}>
                                                    <li><span>Big 4 bank with interest rate discount applicable for first 2 years</span></li>
                                                    <li><span>Owner Occupied with Principal and Interest repayments only</span></li>
                                                    <li><span>No Home Loan Establishment Fee</span></li>
                                                    <li><span>Make additional loan repayments allowed. Redraw facility available</span></li>
                                                </ul>
                                           
                                        </td>
                                        <td>
                                            
                                        <ul className={Styles.dealfeatureList}>
                                                    <li><span>Owner Occupied home loan. Interest only avail for additional 0.30%</span></li>
                                                    <li><span>Owner Occupied with Principal and Interest repayments only</span></li>
                                                    <li><span>100% Offset account. ATM, Phone, BPay and internet banking available</span></li>
                                                    <li><span>Make additional loan repayments allowed. Redraw facility available</span></li>
                                                </ul>
                                            
                                        </td>
                                    </tr>
                                    <tr className={Styles.bottomButtons}>
                                    <th className={Styles.dealContntHeading}></th>
                                        <td className={Styles.moreInfoBtnMain}>
                                            <a href="javascript:void(0)" title="More info" className={Styles.moreInfoBtn}>More info</a>
                                        </td>
                                        <td className={Styles.moreInfoBtnMain}>
                                            <a href="javascript:void(0)" title="More info" className={Styles.moreInfoBtn}>More info</a>
                                        </td>
                                        <td className={Styles.moreInfoBtnMain}>
                                            <a href="javascript:void(0)" title="More info" className={Styles.moreInfoBtn}>More info</a>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>



                       
                    </div>
                </div>
            </div>
        </section>

        {/* <GetStarted/> */}
    </Layout>
)



export default DealComparison;
